import { createContext } from 'react'

export type JobPlanningsFilterType = {
  no: string[]
  job_task_no: string[]
  start_date?: string
  end_date?: string
  production_place?: string
}

export type SavedFilterType = {
  id: string
  name: string
  default: boolean
  filter: any
}

export type CurrentUserType = {
  id: number
  name: string
  username: string
  email: string
  saved_filters: Array<SavedFilterType>
  saved_production_filters: Array<SavedFilterType>
  allow_to_add_job_plannings: boolean
  is_department_manager: boolean
  hide_machines: boolean
  hide_items: boolean
  hide_packagings: boolean
  hide_shipments: boolean
  areas: string[]
  default_machines?: {
    id: number
    no: string
    name: string
  }[]
}

export type WarehouseType = {
  id: number
  batch_name: string
  title: string
  location_code: string
  location_code_title: string
  stock_level_code: string
  is_default_for_west: boolean
  is_default_for_east: boolean
}

export type DictionariesContextType = {
  currentUser: CurrentUserType
  smProjectUrl?: string
  createSMProjectUrl?: string
  updateCurrentUser?: (currentUser: CurrentUserType) => void
  availableWarehouses?: WarehouseType[]
  allPartners: any[]
  availableUsers?: any[]
  availableApprovalFormTemplates?: any[]
  availableAreas?: any[]
  availableMachines?: any[]
  availableWorktypes?: any[]
  availableWorkcards?: any[]
  availablePartners?: any[]
  availableTasks?: any[]
}

const DictionariesContext = createContext<DictionariesContextType>(null)

export default DictionariesContext