import * as React from 'react'

type RemoveFieldsButtonProps = {
  handleRemove: () => void
  className?: string
}

export default function RemoveFieldsButton({ handleRemove, className }: RemoveFieldsButtonProps) {
  const classes = `${className} remove-fields-button  fa fa-icon fa-close`
  return <i role="button" className={classes} onClick={handleRemove} />
}

