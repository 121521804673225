import * as React from 'react'
import { useEffect, useState } from 'react'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'
import ItemPicker from '../resources_records/ItemPicker'
import { stockLevelInWarehouse } from '../helpers/items'

function initialItem(item) {
  if (item) {
    return {
      label: item.title,
      value: item.id,
      item,
    }
  }
  return null
}

type WarehouseItemFieldsProps = {
  idKey: string
  index: number
  itemFields: {
    id: number
    item: any
    amount: number
    entry_type: string
    description: string
  }
  setItemFields: (itemFields: any) => void
  cloneItemFields: (itemFields: any) => void
  removeItemFields: (idKey: string) => void
  showRemoveItemFieldsButton: boolean
  availableWarehouses: any[]
  warehouseId: number
}

export default function WarehouseItemFields(props: WarehouseItemFieldsProps) {
  const {
    idKey,
    index,
    itemFields,
    setItemFields,
    cloneItemFields,
    removeItemFields,
    showRemoveItemFieldsButton,
    availableWarehouses,
    warehouseId,
  } = props
  const amount = itemFields.amount || 0
  const entryType = itemFields.entry_type || 'down'

  const fieldId = field => `${idKey}-field-${field}`
  const fieldName = field => `resources_warehouse[warehouse_items_attributes][${index}][${field}]`
  const id = itemFields.id || null
  const [item, setItem] = useState(initialItem(itemFields.item))
  useEffect(() => {
    if (item) {
      setItemFields({ ...itemFields, item_id: item.value })
      setItemFields({ ...itemFields, description: item.item.description })
    }
  }, [item])
  const baseUnit = (item && item.item && item.item.base_unit_of_measure) || null
  // const stockLevel = item && item.item ? item.item.inventory : null
  const stockLevel = stockLevelInWarehouse(item, warehouseId, availableWarehouses)
  const isOverStock = stockLevel === null ? false : stockLevel < amount
  const description = itemFields.description || ''

  return (
    <fieldset className="resources-warehouse-item-fields form-fieldset mb-4">
      {showRemoveItemFieldsButton && (
        <RemoveFieldsButton className="resources-remove-fields-button" handleRemove={() => removeItemFields(idKey)} />
      )}
      <div className="row">
        <div className="col-sm-8 form-group resources-warehouse-item-item">
          <label htmlFor={fieldId('item_id')}>Vareforbrug</label>
          <ItemPicker item={item} setItem={setItem} id={fieldId('item_id')} name={fieldName('item_id')} />
        </div>
        <div className="col-sm-4">
          <label htmlFor={fieldId('description')}>Op- eller nedregulering</label>
          <div className="btn-group btn-group-toggle w-100">
            <label className={`btn btn-outline-secondary ${entryType === 'down' ? 'active' : ''}`}>
              <input
                type="radio"
                name={fieldName('entry_type')}
                id={fieldId('entry_type_down')}
                checked={entryType === 'down'}
                value="down"
                onChange={e => {
                  setItemFields({ ...itemFields, entry_type: 'down' })
                }}
              />
              NED
            </label>
            <label className={`btn btn-outline-secondary ${entryType === 'up' ? 'active' : ''}`}>
              <input
                type="radio"
                name={fieldName('entry_type')}
                id={fieldId('entry_type_up')}
                checked={entryType === 'up'}
                value="up"
                onChange={e => {
                  setItemFields({ ...itemFields, entry_type: 'up' })
                }}
              />
              OP
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8 form-group">
          <label htmlFor={fieldId('description')}>Beskrivelse</label>
          <input
            className="form-control"
            type="text"
            id={fieldId('description')}
            name={fieldName('description')}
            value={description}
            onChange={e => setItemFields({ ...itemFields, description: e.target.value })}
          />
        </div>
        <div className="col-sm-4 resources-item-col-2">
          <div className="form-group resources-item-input">
            <label htmlFor={fieldId('amount')}>Antal</label>
            <div className="input-group">
              <div className="input-group-prepend">
                {entryType === 'up' && <span className="input-group-text post-type-up">+</span>}
                {entryType === 'down' && <span className="input-group-text post-type-down">-</span>}
              </div>
              <input
                className={`form-control ${isOverStock ? 'resources-item-over-stock' : ''}`}
                id={fieldId('amount')}
                type="text"
                step="0.1"
                pattern="[0-9]+([.,][0-9]{1,2})?"
                name={fieldName('amount')}
                value={amount}
                required
                onFocus={e => e.target.select()}
                onChange={e => setItemFields({ ...itemFields, amount: e.target.value })}
              />
              <div className="input-group-append">
                <span className="input-group-text">{baseUnit}</span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>&nbsp;</label>
            <div className="input-group">
              <button className="btn btn-primary" type="button" onClick={() => cloneItemFields(itemFields)}>
                +
              </button>
            </div>
          </div>
        </div>
        {id && <input type="hidden" name={fieldName('id')} value={id} />}
      </div>
      {item && item.item && (
        <div className="text-right">
          <span className="mr-2">
            Lager: <span className="badge badge-light">{item.item.inventory}</span>
          </span>
          <span className="mr-2">
            Måløv: <span className="badge badge-light">{item.item.inventory_malov}</span>
          </span>
          <span className="mr-2">
            Skejby: <span className="badge badge-light">{item.item.inventory_skejby}</span>
          </span>
          <span className="mr-2">
            Lyfa: <span className="badge badge-light">{item.item.inventory_lyfa}</span>
          </span>
          <span>
            Fjernlager: <span className="badge badge-light">{item.item.inventory_fjernlager}</span>
          </span>
        </div>
      )}
      {isOverStock && <div className="resources-item-over-stock-notice">* Antal overstiger antal på lager ({stockLevel})</div>}
    </fieldset>
  )
}
