import * as React from 'react'
import { ResourcesRecordShape } from '../../api/api_data_types'

type ResourcesRegistrationsRecordShipmentsProps = {
  record: ResourcesRecordShape
}

export default function ResourcesRegistrationsRecordShipments(props: ResourcesRegistrationsRecordShipmentsProps) {
  const { record } = props
  if (!record.shipments || record.shipments.length === 0) {
    return null
  }
  return (
    <table className="table table-sm table-responsive">
      <thead>
        <tr>
          <th colSpan={3}>Forsendelser</th>
        </tr>
        <tr>
          <th>Beskrivelse</th>
          <th>Beløb</th>
        </tr>
      </thead>
      <tbody>
        {record.shipments.map(resourceShipment => (
          <tr key={resourceShipment.id}>
            <td>{resourceShipment.provider}</td>
            <td>{resourceShipment.price}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
