import axios, { AxiosRequestConfig } from 'axios'
import { available_worktypes_api_user_path, save_filters_users_path } from '../routes'

export function getAvailableWorktypesResourcesRecords(payload: { user_id: number }) {
  const userId = payload.user_id
  const options: AxiosRequestConfig = {
    url: available_worktypes_api_user_path(userId, { no_absence: true, format: 'json' }),
    method: 'get',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}

export function saveUserFilters(payload = {}) {
  const options: AxiosRequestConfig = {
    url: save_filters_users_path({ format: 'json' }),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}
