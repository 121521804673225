import * as React from 'react'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'
import MinutesPicker from './MinutesPicker'
import HoursPicker from './HoursPicker'
import RemovedField from "./RemovedField";
import QuickTimePicker from "./QuickTimePicker";

type MachineFieldsProps = {
  className: string
  idKey: string
  machineFields: {
    time: number
    machine_id: number
    id: number
    _destroy: boolean
  }
  setMachineFields: (machineFields: any) => void
  availableMachines: any[]
  fieldNameFn: (field: string) => string
  handleRemove: () => void
}

export default function MachineFields(props: MachineFieldsProps) {
  const {
    className,
    idKey,
    machineFields,
    setMachineFields,
    availableMachines,
    fieldNameFn,
    handleRemove,
  } = props

  const machineId = machineFields.machine_id || ''
  const minutes = machineFields.time % 60 || 0
  const hours = Math.floor(machineFields.time / 60) || 0
  const fieldId = field => `${idKey}-field-${field}`
  const id = machineFields.id || null

  if (machineFields._destroy === true) {
    return <RemovedField field={machineFields} fieldNameFn={fieldNameFn} />
  }
  return (
    <fieldset className={ `position-relative form-fieldset ${className}` }>
      <RemoveFieldsButton className="resources-remove-fields-button" handleRemove={handleRemove} />
      <div className="row">
        <div className="col-12 form-group">
          <label htmlFor={fieldId('machine_id')}>Maskine/udstyr</label>
          <select
            id={fieldId('machine_id')}
            name={fieldNameFn('machine_id')}
            className="form-control resources-machine-machine"
            value={machineId}
            onChange={e => setMachineFields({ ...machineFields, machine_id: e.target.value })}
            required
          >
            <option />
            {availableMachines.map(machine => {
              const { id, name } = machine
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              )
            })}
          </select>
        </div>
      </div>
      <div className="row">
        <QuickTimePicker
          className="col-md-7"
          setTime={time => setMachineFields({ ...machineFields, time })}
        />
        <div className="col-md-5">
          <div className="resources-time-selects">
            <div className="form-group pr-1 time-picker-field">
              <label>Tid</label>
              <HoursPicker
                hours={hours}
                setHours={h => setMachineFields({ ...machineFields, time: h * 60 + minutes })}
                name={fieldNameFn('hours')}
              />
            </div>

            <div className="form-group pr-1 time-picker-field">
              <label>&nbsp;</label>
              <MinutesPicker
                minutes={minutes}
                setMinutes={m => setMachineFields({ ...machineFields, time: hours * 60 + m })}
                name={fieldNameFn('minutes')}
              />
            </div>
            <input type="hidden" name={fieldNameFn('time')} value={hours * 60 + minutes} />
            {id && <input type="hidden" name={fieldNameFn('id')} value={id} />}
          </div>
        </div>
      </div>
    </fieldset>
  )
}

