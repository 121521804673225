import * as React from 'react'

type MinutesPickerProps = {
  minutes: number
  setMinutes: (minutes: number) => void
  name: string
  disabled?: boolean
}

export default function MinutesPicker({ minutes, setMinutes, name, disabled }: MinutesPickerProps) {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">M</span>
      </div>
      <select
        name={name}
        className="form-control resources-time-minutes"
        value={minutes}
        onChange={e => setMinutes(parseInt(e.target.value, 10))}
        disabled={disabled}
      >
        {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map(val => {
          return (
            <option key={val} value={val}>
              {val}
            </option>
          )
        })}
      </select>
    </div>
  )
}
