import * as React from 'react'

type QuickTimePickerProps = {
  setTime: (time: number) => void
  className: string
  disabled?: boolean
}

export default function QuickTimePicker(props: QuickTimePickerProps) {
  const { setTime, className, disabled } = props

  const clickHandlerFunction = (time: number) => {
    return e => {
      e.preventDefault()
      setTime(time)
    }
  }
  const quickTimes = [5, 10, 15, 20, 25, 30]
  const lastTime = quickTimes[quickTimes.length - 1]
  return (
    <div className={className}>
      <label>Hurtig tid (min.)</label>
      <span className="d-inline-block">
        {quickTimes.map((time, index) => (
          <button
            className={`btn btn-outline-secondary ${time === lastTime ? '' : 'mr-2'}`}
            disabled={disabled}
            key={index}
            onClick={clickHandlerFunction(time)}
          >
            {time}
          </button>
        ))}
      </span>
    </div>
  )
}
