import * as React from 'react'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'
import RemovedField from './RemovedField'

type PurchaseFieldsProps = {
  className?: string
  idKey: string
  purchaseFields: {
    description: number
    cost: number
    id: number
    _destroy: boolean
  }
  setPurchaseFields: (machineFields: any) => void
  fieldNameFn: (field: string) => string
  handleRemove: () => void
}

export default function PurchaseFields(props: PurchaseFieldsProps) {
  const { className, idKey, purchaseFields, setPurchaseFields, fieldNameFn, handleRemove } = props

  const fieldId = field => `${idKey}-field-${field}`
  const id = purchaseFields.id || null

  if (purchaseFields._destroy === true) {
    return <RemovedField field={purchaseFields} fieldNameFn={fieldNameFn} />
  }
  return (
    <fieldset className={`position-relative form-fieldset mb-2 ${className}`}>
      <RemoveFieldsButton className="resources-remove-fields-button" handleRemove={handleRemove} />
      <div className="row">
        <div className="col-12 form-group">
          <label htmlFor={fieldId('description')}>Beskrivelse</label>
          <input
            id={fieldId('description')}
            name={fieldNameFn('description')}
            className="form-control resources-purchase-description"
            value={purchaseFields.description || ''}
            onChange={e => setPurchaseFields({ ...purchaseFields, description: e.target.value })}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <label htmlFor={fieldId('cost')}>Beløb</label>
          <input
            type="text"
            id={fieldId('cost')}
            className="form-control resources-purchase-cost"
            value={purchaseFields.cost || ''}
            pattern="[0-9]+([.,][0-9]{1,2})?"
            name={fieldNameFn('cost')}
            onFocus={e => e.target.select()}
            onChange={e => setPurchaseFields({ ...purchaseFields, cost: e.target.value })}
            required
          />
          {id && <input type="hidden" name={fieldNameFn('id')} value={id} />}
        </div>
      </div>
    </fieldset>
  )
}
