import * as React from 'react'
import { useEffect } from 'react'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'
import MinutesPicker from './MinutesPicker'
import HoursPicker from './HoursPicker'
import { separateTime } from '../shared/utils'
import RemovedField from './RemovedField'
import QuickTimePicker from './QuickTimePicker'
import TimeFieldsSignControl from './TimeFieldsSignControl'
import { PartnerShape } from '../../api/api_data_types'
import PartnerPicker from './PartnerPicker'
import WorktypePicker from './WorktypePicker'

type TimeFieldsProps = {
  idKey: string
  timeFields: {
    id: number
    time: number
    worktype_id: number
    comment: string
    _destroy: boolean
    partner_id?: number
    partner_time?: boolean
    record_id?: number
    exported?: boolean
    approved?: boolean
  }
  setTimeFields: (timeFields: any) => void
  showSignControl: boolean
  availableWorktypes: [string, number][]
  fieldNameFn: (field: string) => string
  handleRemove: () => void
  availablePartners: PartnerShape[]
  allPartners: PartnerShape[]
  allowRemove: boolean
  className?: string
}

export default function TimeFields(props: TimeFieldsProps) {
  const {
    className,
    idKey,
    timeFields,
    setTimeFields,
    showSignControl,
    availableWorktypes,
    fieldNameFn,
    handleRemove,
    availablePartners,
    allPartners,
    allowRemove,
  } = props
  const timeId = timeFields.id || null
  const worktypeId = timeFields.worktype_id || ''
  const comment = timeFields.comment || ''
  let signMultiplier = timeFields.time && timeFields.time < 0 ? -1 : 1
  const [hours, minutes] = separateTime(Math.abs(timeFields.time))
  useEffect(() => {
    signMultiplier = timeFields.time && timeFields.time < 0 ? -1 : 1
  }, [timeFields.time])
  const fieldId = field => `${idKey}-field-${field}`
  const partnerId = timeFields.partner_id || null

  if (timeFields._destroy === true) {
    return (
      <>
        <RemovedField field={timeFields} fieldNameFn={fieldNameFn} />
        {partnerId && <input type="hidden" name={fieldNameFn('partner_id')} value={partnerId} />}
      </>
    )
  }

  const isPartnerTime = timeFields.partner_time || false
  const disabled = isPartnerTime && (!!timeFields.exported || !!timeFields.approved)
  const partnerDropdownDisabled = !!timeId

  return (
    <fieldset className={`position-relative form-fieldset ${className}`}>
      {isPartnerTime && (
        <PartnerPicker
          disabled={disabled || partnerDropdownDisabled}
          partnerId={partnerId}
          setPartnerId={id => setTimeFields({ ...timeFields, partner_id: id })}
          availablePartners={availablePartners}
          allPartners={allPartners}
          name={fieldNameFn('partner_id')}
        />
      )}
      {isPartnerTime && partnerDropdownDisabled && (
        <input type="hidden" name={fieldNameFn('partner_id')} value={partnerId} disabled={disabled} />
      )}
      <WorktypePicker
        id={fieldId('worktype_id')}
        name={fieldNameFn('worktype_id')}
        partnerId={partnerId}
        isPartnerTime={isPartnerTime}
        availableWorktypes={availableWorktypes}
        worktypeId={worktypeId}
        setWorktypeId={id => setTimeFields({ ...timeFields, worktype_id: id })}
        disabled={disabled}
      />
      <div className="row">
        <div className="col-sm-12 form-group">
          <label htmlFor={fieldId('comment')}>Beskrivelse</label>
          <input
            id={fieldId('comment')}
            name={fieldNameFn('comment')}
            className="form-control resources-time-comment"
            value={comment}
            disabled={disabled}
            onChange={e => setTimeFields({ ...timeFields, comment: e.target.value })}
          />
        </div>
      </div>
      <div className="row">
        <QuickTimePicker
          disabled={disabled}
          className={`${showSignControl ? 'col-md-5' : 'col-md-7'} form-group`}
          setTime={time => setTimeFields({ ...timeFields, time })}
        />
        <div className={showSignControl ? 'col-md-7' : 'col-md-5'}>
          <div className="resources-time-selects">
            <div className="form-group w-100">
              <label className="d-flex justify-content-between">
                <span className="d-inline-block mr-2">Tid</span>
              </label>

              <div className="d-flex">
                {showSignControl && (
                  <TimeFieldsSignControl setTimeFields={setTimeFields} timeFields={timeFields} signMultiplier={signMultiplier} />
                )}
                <div className="mr-2 time-picker-field">
                  <HoursPicker
                    hours={hours}
                    disabled={disabled}
                    setHours={h => setTimeFields({ ...timeFields, time: (h * 60 + minutes) * signMultiplier })}
                    name={fieldNameFn('hours')}
                  />
                </div>

                <div className="time-picker-field">
                  <MinutesPicker
                    minutes={minutes}
                    setMinutes={m => setTimeFields({ ...timeFields, time: (hours * 60 + m) * signMultiplier })}
                    name={fieldNameFn('minutes')}
                    disabled={disabled}
                  />
                  <input className="invisible-field" type="number" onChange={() => {}} value={hours * 60 + minutes} min={5} />
                </div>
              </div>
            </div>
            <input type="hidden" disabled={disabled} name={fieldNameFn('time')} value={(hours * 60 + minutes) * signMultiplier} min={5} />
            {timeId && <input type="hidden" disabled={disabled} name={fieldNameFn('id')} value={timeId} />}
          </div>
        </div>
      </div>

      {allowRemove && !disabled && <RemoveFieldsButton className="resources-remove-fields-button" handleRemove={handleRemove} />}
    </fieldset>
  )
}
