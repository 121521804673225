import axios, { AxiosRequestConfig } from 'axios'
import { api_items_path, move_api_item_path, related_for_jobnumber_api_items_path } from '../routes'

function convertToOptions(response, callback) {
  const { data } = response
  if (data) {
    const options = data.map(item => {
      return { label: item.title, value: item.id, item: item }
    })
    callback(options)
  } else {
    callback([])
  }
}

export function loadItemOptions(filter, callback) {
  const ajaxParams: AxiosRequestConfig = {
    url: api_items_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: { q: filter },
  }
  axios(ajaxParams).then(response => {
    convertToOptions(response, callback)
  })
}

export function loadJobCostingItemOptions(filter, callback) {
  const ajaxParams: AxiosRequestConfig = {
    url: api_items_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: { q: filter, search_type: 'job_costing' },
  }
  axios(ajaxParams).then(response => {
    convertToOptions(response, callback)
  })
}

export function loadPackagingsItemOptions(filter, callback) {
  const ajaxParams: AxiosRequestConfig = {
    url: api_items_path({ format: 'json' }),
    method: 'get',
    responseType: 'json',
    params: { q: filter, search_type: 'packagings' },
  }
  axios(ajaxParams).then(response => {
    convertToOptions(response, callback)
  })
}

export function moveItem(id: number, payload: any) {
  const options: AxiosRequestConfig = {
    url: move_api_item_path(id, { format: 'json' }),
    method: 'put',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}

export function getRelatedForJobNumber(jobnumberId, taskId) {
  const options: AxiosRequestConfig = {
    url: related_for_jobnumber_api_items_path({ jobnumber_id: jobnumberId, task_id: taskId, format: 'json' }),
    method: 'get',
    responseType: 'json',
  }
  return axios(options).then(response => response.data)
}
