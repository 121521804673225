import * as React from 'react'
import { ResourcesRecordShape } from '../../api/api_data_types'
import ResourcesRegistrationsRecordTimes from './ResourcesRegistrationsRecordTimes'
import ResourcesRegistrationsRecordMachines from './ResourcesRegistrationsRecordMachines'
import ResourcesRegistrationsRecordItems from './ResourcesRegistrationsRecordItems'
import ResourcesRegistrationsRecordShipments from "./ResourcesRegistrationsRecordShipments";
import ResourcesRegistrationsRecordPackagings from "./ResourcesRegistrationsRecordPackagings";

type ResourcesRegistrationsRecord = {
  record: ResourcesRecordShape
}

export default function ResourcesRegistrationsRecord(props: ResourcesRegistrationsRecord) {
  const { record } = props
  return (
    <div className="mb-4">
      <div className="orders-cases-registrations-date p-2">
        {record.date}
      </div>
      <ResourcesRegistrationsRecordTimes record={record} />
      <ResourcesRegistrationsRecordMachines record={record} />
      <ResourcesRegistrationsRecordItems record={record} />
      <ResourcesRegistrationsRecordShipments record={record} />
      <ResourcesRegistrationsRecordPackagings record={record} />
    </div>
  )
}
