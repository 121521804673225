import axios, { AxiosRequestConfig } from 'axios'
import { overview_resources_records_path, resources_overview_comments_path, resources_records_path, registrations_api_resources_records_path } from '../routes'

export function getResourcesOverview(payload = {}) {
  const options: AxiosRequestConfig = {
    url: overview_resources_records_path({format: 'json'}),
    method: 'get',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}

export function getResourcesRecordsRegistrations(payload = {}) {
  const options: AxiosRequestConfig = {
    url: registrations_api_resources_records_path({format: 'json'}),
    method: 'get',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}

export function createResourcesOverviewComment(payload = {}) {
  const options: AxiosRequestConfig = {
    url: resources_overview_comments_path({format: 'json'}),
    method: 'post',
    responseType: 'json',
    params: payload,
  }
  return axios(options).then(response => response.data)
}

export function createResourcesRecord(payload = {}) {
  const options: AxiosRequestConfig = {
    url: resources_records_path({format: 'json'}),
    method: 'post',
    responseType: 'json',
    data: payload,
  }
  return axios(options).then(response => response.data)
}
