import * as React from 'react'
import { JobNumberShape } from '../../api/api_data_types'
import { useResourcesRecordsOverviewQuery } from '../../hooks/queries/resources_records'
import ErrorMessage from '../job_plannings/list_app/ErrorMessage'
import ResourcesRegistrationsGroupCard from './ResourcesRegistrationsGroupCard'

type ResourcesRecordsOverviewProps = {
  jobNumber: JobNumberShape
}

export default function ResourcesRegistrations(props: ResourcesRecordsOverviewProps) {
  const { jobNumber } = props
  const { data, isLoading, isError, error } = useResourcesRecordsOverviewQuery(jobNumber)
  const groupedRecords = data?.data || {}
  return (
    <div className="resources-records-registrations">
      {isLoading && (
        <div className="text-center">
          <i className="fa fa-spinner fa-spin fa-2x" />
        </div>
      )}
      <ErrorMessage className="d-inline error-message" error={error} isError={isError} />
      {data && (
        <div className="accordion">
          {Object.keys(groupedRecords).map(key => {
            const records = groupedRecords[key]
            return <ResourcesRegistrationsGroupCard key={key} title={key} records={records} />
          })}
        </div>
      )}
    </div>
  )
}
