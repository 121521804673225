import * as React from 'react'
import { useState } from 'react'
import Select from 'react-select'

type PartnersPickerProps = {
  partnerId: number
  setPartnerId: (partnerId: number) => void
  availablePartners: any[]
  allPartners: any[]
  name: string
  disabled?: boolean
}

export default function PartnerPicker(props: PartnersPickerProps) {
  const { partnerId, setPartnerId, name, availablePartners, allPartners, disabled } = props
  const [showAllUsers, setShowAllUsers] = useState(false)
  const options = (showAllUsers ? allPartners : availablePartners).map(card => {
    return { value: card.id, label: card.name }
  })
  const selected = options.filter(option => partnerId === option.value)
  return (
    <div className="row">
      <div className="col-sm-12 form-group">
        <label>
          Partner
          {allPartners.length > 0 && (
            <span className="ml-3">
              <input type="checkbox" checked={showAllUsers} disabled={!!disabled} onChange={e => setShowAllUsers(e.target.checked)} />
              <span className="record-partners-select-show-all">Vis alle</span>
            </span>
          )}
        </label>
        <Select
          id="record-partner-select"
          value={selected}
          onChange={option => setPartnerId(option.value)}
          name={name}
          options={options}
          isDisabled={!!disabled}
        />
      </div>
    </div>
  )
}

