import * as React from 'react'
import { useState } from 'react'
import OrderNumberPicker from './OrderNumberPicker'
import * as PropTypes from 'prop-types'
import WorkcardsPicker from './WorkcardsPicker'
import TimeFields from './TimeFields'
import MachineFields from './MachineFields'
import ItemFields from './ItemFields'
import PurchaseFields from './PurchaseFields'
import DependentTaskPicker from './DependentTaskPicker'
import NestedFieldset from './NestedFieldset'
import useNestedAttributes from '../../hooks/useNestedAttributes'
import { loadPackagingsItemOptions } from '../../api/items'
import ShipmentFields from './ShipmentFields'
import PullShipmentsButton from './PullShipmentsButton'
import { WarehouseType } from '../DictionariesContext'
import { useRelatedItemsQuery } from '../../hooks/queries/items'
import RelatedItems from './RelatedItems'
import AddTimeForPartnerButton from './AddTimeForPartnerButton'
import useTimeForPartner from './useTimeForPartner'
import { PartnerShape } from '../../api/api_data_types'

type FormFieldsProps = {
  record: any
  formData: any
  setFormData: (formData: any) => void
  availableWorktypes: any[]
  availableWorkcards: any[]
  availablePartners: PartnerShape[]
  allPartners: PartnerShape[]
  allowPartners?: boolean
  availableMachines: any[]
  availableWarehouses: WarehouseType[]
  hideMachines?: boolean
  hideItems?: boolean
  hidePackagings?: boolean
  hideShipments?: boolean
}

function FormFields(props: FormFieldsProps) {
  const {
    record,
    formData,
    setFormData,
    availableWorktypes,
    availableWorkcards,
    availablePartners,
    allPartners,
    allowPartners,
    availableMachines,
    availableWarehouses,
    hideMachines,
    hideItems,
    hidePackagings,
    hideShipments,
  } = props
  const [jobnumber, setJobnumber] = useState(record.jobnumber)
  const jobnumberId = (jobnumber && jobnumber.id) || 0

  const [times, noVisibleTimes, addTime, removeTime, setTime] = useNestedAttributes(formData, setFormData, 'times_attributes')
  const { addTimeForPartner } = useTimeForPartner(formData, setFormData)

  const [machines, noVisibleMachines, addMachine, removeMachine, setMachine] = useNestedAttributes(
    formData,
    setFormData,
    'machines_attributes'
  )
  const [items, noVisibleItems, addItem, removeItem, setItem] = useNestedAttributes(formData, setFormData, 'items_attributes')
  const [packagings, noVisiblePackagings, addPackaging, removePackaging, setPackaging] = useNestedAttributes(
    formData,
    setFormData,
    'packagings_attributes'
  )
  const [shipments, noVisibleShipments, _addShipment, _removeShipment, setShipment] = useNestedAttributes(
    formData,
    setFormData,
    'shipments_attributes'
  )
  const [purchases, noVisiblePurchases, addPurchase, removePurchase, setPurchase] = useNestedAttributes(
    formData,
    setFormData,
    'purchases_attributes'
  )

  const showMachines = !hideMachines && record.is_master
  const showItems = !hideItems && record.is_master

  const { data: relatedItemsData } = useRelatedItemsQuery(formData['jobnumber_id'], formData['task_id'], showItems)

  return (
    <>
      <OrderNumberPicker
        orderNumber={jobnumber}
        setOrderNumber={jn => {
          setJobnumber(jn)
          if (jn && jn.id && jn.id > 0) {
            setFormData({ ...formData, jobnumber_id: jn.id })
          }
        }}
      />
      <input type="hidden" name="resources_record[jobnumber_id]" value={jobnumberId} />
      <div className="row">
        <div className="col-sm-6">
          <DependentTaskPicker
            jobnumber={jobnumber}
            taskId={formData.task_id}
            setTaskId={id => setFormData({ ...formData, task_id: id })}
            name="resources_record[task_id]"
          />
        </div>
        <div className="col-sm-6 form-group">
          <label htmlFor="resources-record-date">Dato</label>
          <input
            id="resources-record-date"
            required
            type="date"
            className="form-control col-xs-12"
            value={formData.date}
            onChange={e => setFormData({ ...formData, date: e.target.value })}
            name="resources_record[date]"
          />
        </div>
      </div>

      <NestedFieldset
        className="mb-4"
        title="Timer"
        addButtonTitle="+ Tilføj"
        headerButtons={
          allowPartners && (
            <AddTimeForPartnerButton
              disabled={!record.is_master || Object.keys(times).length === 0}
              addTimeForPartner={addTimeForPartner}
            />
          )
        }
        onAddButtonClick={addTime}
      >
        {noVisibleTimes && <div className="text-muted text-center">Ingen timer</div>}
        {Object.keys(times).map((key, index) => {
          const time = times[key]
          const isLastItem = Object.keys(times).length == index + 1
          const isTheOnlyOwnTime = time.partner_time !== true && Object.keys(times).filter(k => times[k].partner_time !== true).length === 1
          const isAnyPartnerTimeOnForm = Object.keys(times).some(k => times[k].partner_time === true)
          return (
            <TimeFields
              key={key}
              className={isLastItem ? '' : 'mb-2'}
              idKey={key}
              fieldNameFn={field => `resources_record[times_attributes][${index}][${field}]`}
              timeFields={time}
              setTimeFields={data => setTime(key, data)}
              availableWorktypes={availableWorktypes}
              handleRemove={() => removeTime(key)}
              showSignControl={false}
              availablePartners={availablePartners}
              allPartners={allPartners}
              allowRemove={!(isTheOnlyOwnTime && isAnyPartnerTimeOnForm)}
            />
          )
        })}
      </NestedFieldset>
      {showMachines && (
        <NestedFieldset className="mb-4" title="Maskiner" addButtonTitle="+ Tilføj" onAddButtonClick={addMachine}>
          {noVisibleMachines && <div className="text-muted text-center">Ingen maskiner</div>}
          {Object.keys(machines).map((key, index) => {
            const machine = machines[key]
            const isLastItem = Object.keys(machines).length == index + 1
            return (
              <MachineFields
                key={key}
                className={isLastItem ? '' : 'mb-2'}
                idKey={key}
                machineFields={machine}
                fieldNameFn={field => `resources_record[machines_attributes][${index}][${field}]`}
                setMachineFields={data => setMachine(key, data)}
                availableMachines={availableMachines}
                handleRemove={() => removeMachine(key)}
              />
            )
          })}
        </NestedFieldset>
      )}
      {showItems && (
        <NestedFieldset className="mb-4" title="Materialer" addButtonTitle="+ Tilføj" onAddButtonClick={addItem}>
          {relatedItemsData && <RelatedItems relatedItems={relatedItemsData.data} formData={formData} setFormData={setFormData} />}
          {noVisibleItems && <div className="text-muted text-center">Ingen materialer</div>}
          {Object.keys(items).map((key, index) => {
            const item = items[key]
            return (
              <ItemFields
                key={key}
                idKey={key}
                itemFields={item}
                availableWarehouses={availableWarehouses}
                fieldNameFn={field => `resources_record[items_attributes][${index}][${field}]`}
                setItemFields={data => setItem(key, data)}
                handleRemove={() => removeItem(key)}
              />
            )
          })}
        </NestedFieldset>
      )}
      {!hidePackagings && (
        <NestedFieldset className="mb-4" title="Emballage" addButtonTitle="+ Tilføj" onAddButtonClick={addPackaging}>
          {noVisiblePackagings && <div className="text-muted text-center">Ingen forsendelser</div>}
          {Object.keys(packagings).map((key, index) => {
            const packaging = packagings[key]
            return (
              <ItemFields
                key={key}
                idKey={key}
                itemFields={packaging}
                availableWarehouses={availableWarehouses}
                loadItemOptions={loadPackagingsItemOptions}
                fieldNameFn={field => `resources_record[packagings_attributes][${index}][${field}]`}
                setItemFields={data => setPackaging(key, data)}
                handleRemove={() => removePackaging(key)}
              />
            )
          })}
        </NestedFieldset>
      )}
      {!hideShipments && (
        <NestedFieldset
          className="mb-4"
          title="Forsendelser"
          headerButtons={
            <PullShipmentsButton
              jobnumberId={jobnumberId}
              setShipments={data =>
                setFormData({
                  ...formData,
                  shipments_attributes: data,
                })
              }
            />
          }
        >
          {noVisibleShipments && <div className="text-muted text-center">Ingen forsendelser</div>}
          <ShipmentFields
            shipmentFields={shipments}
            fieldNameFn={(index, field) => `resources_record[shipments_attributes][${index}][${field}]`}
            setShipmentFields={shipments => setFormData({ ...formData, shipments_attributes: shipments })}
            jobnumberId={jobnumberId}
            awaitShipmentsCost={record.await_shipments_cost}
          />
        </NestedFieldset>
      )}
      {record.is_master && (
        <NestedFieldset className="mb-4" title="Indkøb" addButtonTitle="+ Tilføj" onAddButtonClick={addPurchase}>
          {noVisiblePurchases && <div className="text-muted text-center">Ingen indkøb</div>}
          {Object.keys(purchases).map((key, index) => {
            const purchase = purchases[key]
            return (
              <PurchaseFields
                key={key}
                idKey={key}
                purchaseFields={purchase}
                fieldNameFn={field => `resources_record[purchases_attributes][${index}][${field}]`}
                setPurchaseFields={data => setPurchase(key, data)}
                handleRemove={() => removePurchase(key)}
              />
            )
          })}
        </NestedFieldset>

      )}
      {record.is_master && (
        <WorkcardsPicker
          workcardIds={formData.workcard_ids}
          setWorkcardIds={ids => setFormData({ ...formData, workcard_ids: ids })}
          availableWorkcards={availableWorkcards}
          name="resources_record[workcard_ids][]"
        />
      )}
    </>
  )
}

FormFields.propTypes = {
  availableWorktypes: PropTypes.array.isRequired,
  availableWorkcards: PropTypes.array.isRequired,
  availablePartners: PropTypes.array.isRequired,
  availableMachines: PropTypes.array.isRequired,
  allPartners: PropTypes.array.isRequired,
  hideMachines: PropTypes.bool,
  hideItems: PropTypes.bool,
  record: PropTypes.shape({
    jobnumber_id: PropTypes.object,
    workcard_ids: PropTypes.array,
    partner_ids: PropTypes.array,
    jobnumber: PropTypes.object,
    times: PropTypes.array,
    machines: PropTypes.array,
    is_master: PropTypes.bool,
  }).isRequired,
}

export default FormFields
