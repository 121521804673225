import * as React from 'react'
import { useGetAvailableWorktypesForPartnerQuery } from '../../hooks/queries/users'
import { WorktypeShape } from '../../api/api_data_types'

type WorktypePickerProps = {
  id: string
  name: string
  availableWorktypes: any[]
  worktypeId: string | number
  setWorktypeId: (worktypeId: string | number) => void
  partnerId?: number
  isPartnerTime: boolean
  disabled?: boolean
}

export default function WorktypePicker(props: WorktypePickerProps) {
  const { id, name, availableWorktypes, worktypeId, setWorktypeId, partnerId, isPartnerTime, disabled } = props
  const { data: partnerAvailableWorktypes, isLoading } = useGetAvailableWorktypesForPartnerQuery(partnerId)
  const partnerAvailableWorktypesAsOptions = partnerAvailableWorktypes?.data?.map((worktype: WorktypeShape) => {
    return [`${worktype.code} - ${worktype.title}`, worktype.id]
  })
  const options = partnerId ? partnerAvailableWorktypesAsOptions : availableWorktypes
  return (
    <div className="row">
      <div className="col-sm-12 form-group">
        <label htmlFor={id}>
          Arbejdstype
          {partnerId && isLoading && <i className="fa fa-spinner fa-spin" />}
        </label>
        <select
          id={id}
          name={name}
          className="form-control resources-time-worktype"
          value={worktypeId}
          disabled={(isPartnerTime && !partnerId) || isLoading || disabled}
          onChange={e => setWorktypeId(e.target.value)}
          required
        >
          <option />
          {options &&
            options.map(worktype => {
              const [title, id] = worktype
              return (
                <option key={id} value={id}>
                  {title}
                </option>
              )
            })}
        </select>
      </div>
    </div>
  )
}
