import * as React from 'react'
import { ResourcesRecordShape } from '../../api/api_data_types'
import { minutesToTime } from '../shared/utils'

type ResourcesRegistrationsRecordMachines = {
  record: ResourcesRecordShape
}

export default function ResourcesRegistrationsRecordMachines(props: ResourcesRegistrationsRecordMachines) {
  const { record } = props
  if (record.machines.length === 0) {
    return null
  }
  return (
    <table className="table table-sm table-responsive">
      <thead>
        <tr>
          <th colSpan={3}>Machines</th>
        </tr>
        <tr>
          <th>Time</th>
          <th>Enhed</th>
          <th>Maskiner</th>
        </tr>
      </thead>
      <tbody>
        {record.machines.map(resourceMachine => (
          <tr key={resourceMachine.id}>
            <td>{minutesToTime(resourceMachine.time)}</td>
            <td>Time</td>
            <td>
              {resourceMachine.machine.no} - {resourceMachine.machine.name}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
