import * as React from 'react'
import {useContext, useEffect, useState} from 'react'
import OrderNumberPicker from '../resources_records/OrderNumberPicker'
import FilesTable from '../job_plannings/FilesTable'
import JobPlanningsTable from './JobPlanningsTable'
import MessagesTable from './MessagesTable'
import ApprovalFormsTable from '../job_plannings/ApprovalFormsTable'
import EditStatusButton from '../job_plannings/EditStatusButton'
import Tab from './Tab'
import DictionariesContext from '../DictionariesContext'
import checkFeatureAvailability from '../helpers/features'
import ConsumptionsTable from './ConsumptionsTable'
import useFetchOrderStatus from '../../hooks/useFetchOrderStatus'
import { JobNumberShape } from '../../api/api_data_types'
import ResourcesRegistrations from './ResourcesRegistrations'

type SearchFilesProps = {
  defaultTab: string
  defaultJobnumber: JobNumberShape
}

function SearchFiles(props: SearchFilesProps) {
  const { defaultTab, defaultJobnumber } = props
  const { currentUser } = useContext(DictionariesContext)

  const [jobnumber, setJobnumber] = useState(defaultJobnumber)
  const [activeTab, setActiveTab] = useState(defaultTab) // [files, job_plannings]
  useEffect(() => {
    if (jobnumber) {
      const newState = { job_id: jobnumber.job_id, tab: activeTab }
      window.history.pushState(newState, '', `?job_id=${jobnumber.job_id}&tab=${activeTab}`)
    }
  }, [jobnumber, activeTab]);
  const { isLoading, data, error, statusCode, setStatusCode } = useFetchOrderStatus(jobnumber)

  const isConsumptionFeatureAvailable = checkFeatureAvailability('consumption', { currentUser })

  return (
    <div className="search-files-app">
      <div className="row">
        <div className="col-12">
          <OrderNumberPicker orderNumber={jobnumber} setOrderNumber={setJobnumber} />
        </div>
        <div className="search-files-status col-sm-12 form-group d-flex flex-wrap">
          <div className="mr-2">
            <span className="mr-2">Status:</span>
            {isLoading && <i className="fa fa-spinner fa-spin" />}
            {error && <i className="error-message">{error.message}</i>}
            {jobnumber && data && <EditStatusButton jobId={jobnumber.job_id} statusCode={statusCode} setStatusCode={setStatusCode} />}
          </div>
          {jobnumber && jobnumber.global_dimension_1_code && (
            <div className="mr-3">
              <span className="mr-1">Produceres i:</span>
              {jobnumber.global_dimension_1_code}
            </div>
          )}
          {jobnumber && jobnumber.salesperson && (
            <div className="mr-3">
              <span className="mr-1">Sælger:</span>
              {jobnumber.salesperson}
            </div>
          )}
          {jobnumber && jobnumber.project_manager && (
            <div className="mr-3">
              <span className="mr-1">Projektansvarlig:</span>
              {jobnumber.project_manager}
            </div>
          )}
          {jobnumber && jobnumber.coordinator && (
            <div className="mr-3">
              <span className="mr-1">Orderstyrer:</span>
              {jobnumber.coordinator}
            </div>
          )}
        </div>

        <div className="col-12">
          <ul className="nav nav-tabs">
            <Tab name="files" title="Filer" setActiveTab={setActiveTab} activeTab={activeTab} />
            <Tab name="job_plannings" title="Stadier" setActiveTab={setActiveTab} activeTab={activeTab} />
            <Tab name="messages" title="Beskeder" setActiveTab={setActiveTab} activeTab={activeTab} />
            <Tab name="approvalForms" title="Godkendelse" setActiveTab={setActiveTab} activeTab={activeTab} />
            {isConsumptionFeatureAvailable && (
              <Tab name="consumptions" title="Forbrugskladde" setActiveTab={setActiveTab} activeTab={activeTab} />
            )}
            <Tab name="registrations" title="Registreringer" setActiveTab={setActiveTab} activeTab={activeTab} />
          </ul>
          <div className="search-files-app-tab-content">
            {!jobnumber && <div className="text-center">Vælg eller skriv sagsnummer</div>}
            {activeTab === 'files' && jobnumber && <FilesTable jobnumber={jobnumber} />}
            {activeTab === 'job_plannings' && jobnumber && <JobPlanningsTable jobnumber={jobnumber} />}
            {activeTab === 'messages' && jobnumber && <MessagesTable jobnumber={jobnumber} />}
            {activeTab === 'approvalForms' && jobnumber && <ApprovalFormsTable jobnumber={jobnumber} />}
            {activeTab === 'consumptions' && jobnumber && isConsumptionFeatureAvailable && jobnumber.job_id !== null && (
              <ConsumptionsTable jobnumber={jobnumber} currentUser={currentUser} />
            )}
            {activeTab === 'registrations' && jobnumber && <ResourcesRegistrations jobNumber={jobnumber} />}
          </div>
        </div>
      </div>
    </div>
  )
}

SearchFiles.propTypes = {}

export default SearchFiles
