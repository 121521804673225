import { useMutation, useQuery } from 'react-query'
import { createResourcesRecord, getResourcesRecordsRegistrations } from '../../api/resources'

export function useCreateResourcesRecordMutation(onSuccess) {
  const options = { onSuccess }
  const mutationFunction = formData => createResourcesRecord(formData)

  return useMutation(mutationFunction, options)
}

export function useResourcesRecordsOverviewQuery(jobNumber) {
  return useQuery(['resources_records_overview', jobNumber.id], () => getResourcesRecordsRegistrations({ jobnumber_id: jobNumber.id }))
}
