import { WarehouseType } from '../DictionariesContext'

export function stockLevelInWarehouse(item, warehouseId: number, availableWarehouses: WarehouseType[]) {
  if (!item || !item.item || !warehouseId || !availableWarehouses) {
    return null
  }
  const warehouse = availableWarehouses.find(wh => wh.id === warehouseId)
  if (!warehouse) {
    return null
  }

  const stock = item.item[`inventory_${warehouse.stock_level_code}`]
  const coefficient = item.item.qty_per_unit_of_measure || 1

  return stock / coefficient
}
