import * as React from 'react'

type RemovedFieldProps = {
  field: {
    id: number
  }
  fieldNameFn: (field: string) => string
}

export default function RemovedField({ field, fieldNameFn }: RemovedFieldProps) {
  const { id } = field
  return (
    <React.Fragment>
      <input type="hidden" name={fieldNameFn('id')} value={id} />
      <input type="hidden" name={fieldNameFn('_destroy')} value="1" />
    </React.Fragment>
  )
}
