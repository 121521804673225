import * as React from 'react'

type NestedFieldsetProps = {
  children: React.ReactNode
  className?: string
  onAddButtonClick?: () => void
  title: string
  addButtonTitle?: string
  headerButtons?: React.ReactNode
}
export default function NestedFieldset(props: NestedFieldsetProps) {
  const { children, className, onAddButtonClick, title, addButtonTitle, headerButtons } = props
  return (
    <fieldset className={`nested-fieldset border rounded-lg ${className}`}>
      <div className="nested-fieldset-header rounded-top sticky-top p-2 d-flex justify-content-between bg-light border-bottom">
        <div className="nested-fieldset-header-title">{title}</div>
        <div className="d-flex">
          {headerButtons}
          {onAddButtonClick && (
            <button className="btn btn-outline-primary btn-sm" type="button" onClick={onAddButtonClick}>
              {addButtonTitle}
            </button>
          )}
        </div>
      </div>
      <div className="nested-fieldset-content p-2">{children}</div>
    </fieldset>
  )
}
