import * as React from 'react'

type AddTimeForPartnerButtonProps = {
  addTimeForPartner: () => void
  disabled?: boolean
}

export default function AddTimeForPartnerButton(props: AddTimeForPartnerButtonProps) {
  const { addTimeForPartner, disabled } = props
  return (
    <button className="btn btn-outline-primary btn-sm mr-2" type="button" onClick={addTimeForPartner} disabled={disabled}>
      + Tilføj tid for partner
    </button>
  )
}
