import * as React from 'react'
import { useState } from 'react'
import Popup from 'reactjs-popup'
import { useQueryClient } from 'react-query'
import { useStorageState } from 'react-storage-hooks'

import EditStatusButton from '../EditStatusButton'
import JobPlanningsTable from './JobPlanningsTable'

type CompletePopupButtonProps = {
  jobnumber: any
  jobPlanning: any
  jobnumberId: number
  buttonClass: string
  currentView: string
}

export default function CompletePopupButton(props: CompletePopupButtonProps) {
  const { jobnumber, jobPlanning, jobnumberId, buttonClass, currentView } = props
  let popupTitle = 'Åbne stadier på'
  if (jobnumber) {
    popupTitle = `${popupTitle} ${jobnumber.order_or_quote_number}`
  }
  const [filter, setFilter] = useStorageState(sessionStorage, 'filter')
  const [showCompletedToday, setShowCompletedToday] = useStorageState(sessionStorage, 'showCompletedToday')
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)
  const togglePopup = () => setOpen(!open)

  const queryClient = useQueryClient()
  return (
    <div>
      <button type="button" className={buttonClass || 'btn btn-outline-secondary'} onClick={togglePopup}>
        Færdig...
      </button>
      <Popup open={open} onClose={closeModal} position="center center" contentStyle={{ width: 'auto', maxWidth: '100%' }} modal nested>
        <div className="complete-button-modal modal-popup">
          <a className="close" onClick={closeModal}>
            &times;
          </a>
          <div className="complete-button-modal-header popup-header">
            {popupTitle}
            <span className="pl-2">
              <EditStatusButton
                jobId={jobPlanning.job_id}
                statusCode={jobPlanning.status_code}
                setStatusCode={newStatus => {
                  queryClient.setQueryData(['job_planning_lines', filter, showCompletedToday], (lines: any) => {
                    return lines.map(line => (line.key === jobPlanning.key ? { ...line, status_code: newStatus } : line))
                  })
                }}
              />
            </span>
          </div>
          <div className="complete-button-modal-content popup-content">
            {open && jobnumberId && <JobPlanningsTable jobnumberId={jobnumberId} currentView={currentView} />}
          </div>
          <div className="complete-button-modal-actions popup-actions">
            <button type="button" className="btn btn-outline-secondary" onClick={closeModal}>
              Luk
            </button>
          </div>
        </div>
      </Popup>
    </div>
  )
}
