import * as React from 'react'
import { useState } from 'react'
import { QueryClientProvider } from 'react-query'
import buildQueryClient from '../helpers/quieryClient'
import FormFields from './FormFields'

type FoundHour = {
  id: number
  department: {
    id: number
  }
  date: string
  saved_type: string
  frequency: string
  minutes: number
  description: string
}

type FindAnHourFormFieldsAppProps = {
  foundHour: FoundHour
  availableDepartments: {
    id: number
    title: string
  }[]
}

export default function FindAnHourFormFieldsApp(props: FindAnHourFormFieldsAppProps) {
  const queryClient = buildQueryClient()
  const { foundHour, availableDepartments } = props
  const [formData, setFormData] = useState({
    department_id: foundHour?.department?.id,
    date: foundHour.date,
    saved_type: foundHour.saved_type || 'saved',
    frequency: foundHour.frequency || 'once',
    minutes: foundHour.minutes || 0,
    description: foundHour.description || "",
  })
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <FormFields
          availableDepartments={availableDepartments}
          formData={formData}
          setFormData={setFormData}
        />
      </QueryClientProvider>
    </>
  )
}
