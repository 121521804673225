import { useQuery } from 'react-query'
import { getAvailableWorktypesResourcesRecords } from '../../api/users'

export function useGetAvailableWorktypesForPartnerQuery(partnerId) {
  const enabled = !!partnerId
  return useQuery(['available_worktypes_for_partner', partnerId], {
    enabled,
    queryFn: () => getAvailableWorktypesResourcesRecords({ user_id: partnerId }),
  })
}
