import * as React from 'react'

type HoursPickerProps = {
  hours: number
  setHours: (hours: number) => void
  name: string
  disabled?: boolean
}

export default function HoursPicker({ hours, setHours, name, disabled }: HoursPickerProps) {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">T</span>
      </div>
      <select
        name={name}
        className="form-control resources-time-hours"
        value={hours}
        disabled={disabled}
        onChange={e => setHours(parseInt(e.target.value, 10))}
      >
        {Array.from({ length: 24 }, (_, val) => (
          <option key={val} value={val}>
            {val}
          </option>
        ))}
      </select>
    </div>
  )
}
