import * as React from 'react'
import { ResourcesRecordShape } from '../../api/api_data_types'
import ResourcesRegistrationsRecord from './ResourcesRegistrationsRecord'

type ResourcesRegistrationsGroupCardProps = {
  title: string
  records: ResourcesRecordShape[]
}

export default function ResourcesRegistrationsGroupCard(props: ResourcesRegistrationsGroupCardProps) {
  const { title, records } = props
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="mb-0">
          <button className="btn btn-link btn-block text-left" type="button" aria-expanded="true" onClick={() => setIsOpen(!isOpen)}>
            {title}
          </button>
        </h2>
      </div>
      <div className={`collapse ${isOpen ? 'show' : ''}`}>
        <div className="card-body">
          {records.map(record => (
            <ResourcesRegistrationsRecord record={record} key={record.id} />
          ))}
        </div>
      </div>
    </div>
  )
}
