import * as React from 'react'
import { ResourcesRecordShape } from '../../api/api_data_types'

type ResourcesRegistrationsRecordItems = {
  record: ResourcesRecordShape
}

export default function ResourcesRegistrationsRecordItems(props: ResourcesRegistrationsRecordItems) {
  const { record } = props
  if (record.items.length === 0) {
    return null
  }
  return (
    <table className="table table-sm table-responsive">
      <thead>
        <tr>
          <th colSpan={3}>Items</th>
        </tr>
        <tr>
          <th>Antal</th>
          <th>Enhed</th>
          <th>Vare</th>
        </tr>
      </thead>
      <tbody>
        {record.items.map(resourceItem => (
          <tr key={resourceItem.id}>
            <td>{resourceItem.amount}</td>
            <td>{resourceItem.item.base_unit_of_measure}</td>
            <td>{resourceItem.item.no} - {resourceItem.item.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
