import * as React from 'react'
import { useState, useEffect } from 'react'
import RemoveFieldsButton from '../shared/RemoveFieldsButton'
import ItemPicker from './ItemPicker'
import RemovedField from './RemovedField'
import { WarehouseType } from '../DictionariesContext'
import { ItemShape } from '../../api/api_data_types'
import { stockLevelInWarehouse } from '../helpers/items'

type ItemFieldsProps = {
  idKey: string
  itemFields: {
    id: number
    item: ItemShape
    amount: number
    warehouse_id: number
    _destroy: boolean
  }
  setItemFields: (itemFields: any) => void
  fieldNameFn: (field: string) => string
  handleRemove: () => void
  availableWarehouses?: WarehouseType[]
  loadItemOptions?: (filter: any, callback: any) => any
  // loadItemOptions?: (filter: any, callback: any) => Promise<any[]>
}

export default function ItemFields(props: ItemFieldsProps) {
  const { idKey, itemFields, setItemFields, fieldNameFn, handleRemove, availableWarehouses, loadItemOptions = null } = props
  const amount = itemFields.amount || 0
  const fieldId = field => `${idKey}-field-${field}`

  const id = itemFields.id || null
  const defaultItem = itemFields.item
    ? {
        label: itemFields.item.title,
        value: itemFields.item.id,
        item: itemFields.item,
      }
    : null
  const [item, setItem] = useState(defaultItem)
  useEffect(() => {
    if (item) {
      setItemFields({ ...itemFields, item_id: item.value })
    }
  }, [item])
  const defaultWarehouseId = itemFields.warehouse_id || (availableWarehouses ? availableWarehouses[0]['id'] : null)
  const [warehouseId, setWarehouseId] = useState(defaultWarehouseId)
  useEffect(() => {
    if (availableWarehouses && warehouseId) {
      setItemFields({ ...itemFields, warehouse_id: warehouseId })
    }
  }, [warehouseId])
  const baseUnit = (item && item.item && (item.item.default_consumption || item.item.base_unit_of_measure)) || null
  // const stockLevel = item && item.item ? item.item.inventory : null
  const stockLevel = stockLevelInWarehouse(item, warehouseId, availableWarehouses)
  const isOverStock = stockLevel === null ? false : stockLevel < amount

  if (itemFields._destroy === true) {
    return <RemovedField field={itemFields} fieldNameFn={fieldNameFn} />
  }

  return (
    <fieldset className="resources-time-fields form-fieldset">
      <RemoveFieldsButton className="resources-remove-fields-button" handleRemove={handleRemove} />
      <div className="row">
        <div className={`col-sm-${availableWarehouses ? '6' : '9'} form-group`}>
          <label htmlFor={fieldId('item_id')}>Vareforbrug</label>
          <ItemPicker
            customLoadOptions={loadItemOptions}
            setItem={setItem}
            item={item}
            id={fieldId('item_id')}
            name={fieldNameFn('item_id')}
          />
        </div>
        {availableWarehouses && (
          <div className="col-sm-3 form-group">
            <label htmlFor={fieldId('warehouse')}>Lager</label>
            <select
              id={fieldId('warehouse')}
              required
              className="form-control col-xs-12"
              name={fieldNameFn('warehouse_id')}
              value={warehouseId}
              onChange={e => setWarehouseId(parseInt(e.target.value, 10))}
            >
              {availableWarehouses.map(wh => {
                return (
                  <option key={wh.id} value={wh.id}>
                    {wh.location_code_title}
                  </option>
                )
              })}
            </select>
          </div>
        )}
        <div className={`col-sm-3 resources-item-col-2`}>
          <div className="form-group w-100">
            <label htmlFor={fieldId('amount')}>Antal</label>
            <div className="input-group">
              <input
                id={fieldId('amount')}
                className={`form-control ${isOverStock ? 'resources-item-over-stock' : ''}`}
                type="text"
                step="0.1"
                pattern="[0-9]+([.,][0-9]{1,2})?"
                name={fieldNameFn('amount')}
                value={amount}
                onChange={e => setItemFields({ ...itemFields, amount: e.target.value })}
              />
              <div className="input-group-append">
                <span className="input-group-text">{baseUnit}</span>
              </div>
            </div>
          </div>
        </div>
        {id && <input type="hidden" name={fieldNameFn('id')} value={id} />}
      </div>
      {isOverStock && <div className="resources-item-over-stock-notice">* Antal overstiger antal på lager ({stockLevel})</div>}
    </fieldset>
  )
}
