import * as React from 'react'
import uuid from 'uuid/v4'

type RelatedItemsProps = {
  relatedItems: any[]
  formData
  setFormData
}

export default function RelatedItems(props: RelatedItemsProps) {
  const { relatedItems, formData, setFormData } = props
  if (!relatedItems || relatedItems.length == 0) return null

  const handleAddItem = item => {
    const items = formData.items_attributes || {}
    const newItem = { item_id: item.id, item: item }
    setFormData({ ...formData, items_attributes: { ...items, [uuid()]: newItem } })
  }

  const itemAlreadyAdded = item => {
    const items = formData.items_attributes || {}
    return Object.values(items).some(i => i.item_id === item.id)
  }

  return (
    <div className="d-flex justify-content-end mb-2">
      {relatedItems.map((item, index) => (
        <div key={item.id} className="ml-2">
          <button className="btn btn-sm btn-outline-primary" type="button" disabled={itemAlreadyAdded(item)} onClick={() => handleAddItem(item)}>
            + {item.title}
          </button>
        </div>
      ))}
    </div>
  )
}
