import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getRelatedForJobNumber, moveItem } from '../../api/items'

export function useMoveItemMutation(itemId, successCallback) {
  const options = { onSuccess: successCallback }
  return useMutation(payload => moveItem(itemId, payload), options)
}

export function useRelatedItemsQuery(jobnumberId, taskId, enabled) {
  return useQuery(['resources_record_related_items', jobnumberId, taskId], () => getRelatedForJobNumber(jobnumberId, taskId), {
    enabled: enabled && !!jobnumberId && !!taskId,
  })
}
