import * as React from 'react'
import { ResourcesRecordShape } from '../../api/api_data_types'
import { minutesToTime } from '../shared/utils'

type ResourcesRegistrationsRecordTimes = {
  record: ResourcesRecordShape
}

export default function ResourcesRegistrationsRecordTimes(props: ResourcesRegistrationsRecordTimes) {
  const { record } = props
  if (record.times.length === 0) {
    return null
  }

  return (
    <table className="table table-sm table-responsive">
      <thead>
        <tr>
          <th colSpan={5}>Resource</th>
        </tr>
        <tr>
          <th>Time</th>
          <th>Enhed</th>
          <th>Arbejdstype</th>
          <th>Bruger</th>
          <th>Kommentar</th>
        </tr>
      </thead>
      <tbody>
        {record.times.map(resourceTime => (
          <tr key={resourceTime.id}>
            <td>{minutesToTime(resourceTime.time)}</td>
            <td>Time</td>
            <td>
              {resourceTime.worktype.code} - {resourceTime.worktype.title}
            </td>
            <td>{record.username}</td>
            <td>{resourceTime.comment}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
