import uuid from 'uuid/v4'

export default function useTimeForPartners(formData, setFormData) {
  const addTimeForPartner = () => {
    const currentTimeAttributes = formData.times_attributes || {}
    setFormData({
      ...formData,
      times_attributes: {
        ...currentTimeAttributes,
        [uuid()]: { partner_id: null, partner_time: true },
      },
    })
  }

  return { addTimeForPartner }

}
